import { ApolloClient } from '@apollo/client'

import seatQuota from '@/graphql/manage-users/seatQuota.gql'
import logins from '@/graphql/manage-users/logins.gql'
import loginsDisabledCount from '@/graphql/manage-users/loginsDisabledCount.gql'
import loginCreate from '@/graphql/manage-users/loginCreate.gql'
import loginUpdate from '@/graphql/manage-users/loginUpdate.gql'
import passwordReset from '@/graphql/manage-users/passwordReset.gql'
import phoneNumbers from '@/graphql/manage-users/phoneNumbers.gql'
import createAssignPhoneNumbers from '@/graphql/manage-users/createAssignPhoneNumbers.gql'
import releasePhoneNumber from '@/graphql/manage-users/releasePhoneNumber.gql'

import type { ApolloContext } from '@/services/types/Gql'
import type { Pagination } from '@/services/types/Pagination'
import type { SeatQuota, Login } from '@/services/types/ManageUsers'
import type {
  LoginOriginTypeNodeConnection,
  LoginCreate,
  LoginUpdate,
  RequestPasswordReset,
  PhoneNumberOriginTypeNodeConnection,
  CreateAssignPhoneNumbers,
  ReleasePhoneNumber
} from '@/types/generated'

// TODO: move this inside a helper file
const getLimitAndOffset = (pagination: Pagination) => ({
  limit: pagination ? pagination.itemsPerPage : null,
  offset: pagination ? pagination.itemsPerPage * (pagination.page - 1) : null
})

interface IManageUsersGql {
  $apollo: ApolloClient<any>
  seatQuota: (tenantId: string) => Promise<SeatQuota> // No generated types from this query
  logins: (
    tenantId: string,
    pagination: Pagination,
    search: string,
    disabledUsers: boolean,
    ordering: string
  ) => Promise<LoginOriginTypeNodeConnection>
  loginCreate: (login: Login, tenantId: string) => Promise<LoginCreate>
  loginUpdate: (login: Login, tenantId: string) => Promise<LoginUpdate>
  loginUpdateStatus: (login: Login, tenantId: string) => Promise<LoginUpdate>
  passwordReset: (email: string, tenantId: string, returnLink: boolean) => Promise<RequestPasswordReset>
  phoneNumbers: (
    tenantId: string,
    pagination: Pagination,
    search: string
  ) => Promise<PhoneNumberOriginTypeNodeConnection>
  // TODO: pass a prop phoneNumber (create type PhoneNumber) like we do with login on loginCreate & loginUpdate
  createAssignPhoneNumbers: (
    tenantId: string,
    phoneNumberId: string,
    loginId: string,
    primaryPhone: boolean,
    areaCode: string,
    forwardingNumber: string,
    inboxLabel: string
  ) => Promise<CreateAssignPhoneNumbers>
  releasePhoneNumber: (phoneNumberId: string, tenantId: string) => Promise<ReleasePhoneNumber>
}

export default class ManageUsersGql implements IManageUsersGql {
  $apollo: ApolloClient<any>
  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  async seatQuota (tenantId: string) {
    return await this.$apollo.query({
      fetchPolicy: 'no-cache',
      query: seatQuota,
      variables: {
        tenantId
      }
    }).then(result => result?.data.seatQuota as SeatQuota)
  }

  async logins (
    tenantId: string,
    pagination: Pagination,
    search: string,
    disabledUsers = true,
    ordering = ''
  ) {
    const { limit, offset } = getLimitAndOffset(pagination)
    return await this.$apollo.query({
      query: logins,
      fetchPolicy: 'no-cache',
      variables: { tenantId, limit, offset, search, disabledUsers, ordering }
    }).then(res => res?.data.logins as LoginOriginTypeNodeConnection)
  }

  async loginsDisabledCount (tenantId: string, search: string) {
    return await this.$apollo.query({
      query: loginsDisabledCount,
      fetchPolicy: 'no-cache',
      variables: { tenantId, search }
    }).then((result) => {
      return result?.data?.logins?.results?.filter((login: any) => !login.enabled)?.length || 0
    })
  }

  async loginCreate (login: Login, tenantId: string) {
    return await this.$apollo.mutate({
      mutation: loginCreate,
      variables: { ...login, tenantId }
    }).then(result => result?.data.loginCreate as LoginCreate)
  }

  async loginUpdate (login: Login, tenantId: string) {
    return await this.$apollo.mutate({
      mutation: loginUpdate,
      variables: { ...login, tenantId }
    }).then(result => result?.data.loginUpdate as LoginUpdate)
  }

  async loginUpdateStatus (login: Login, tenantId: string) {
    return await this.$apollo.mutate({
      mutation: loginUpdate,
      variables: {
        id: login.id,
        enabled: login.enabled,
        tenantId
      }
    }).then(result => result?.data.loginUpdate as LoginUpdate)
  }

  async passwordReset (email: string, tenantId: string, returnLink = false) {
    return await this.$apollo.mutate({
      mutation: passwordReset,
      variables: {
        email,
        tenantId,
        returnLink
      }
    }).then(result => result?.data.passwordReset as RequestPasswordReset)
  }

  async phoneNumbers (tenantId: string, pagination: Pagination, search: string) {
    const { limit, offset } = getLimitAndOffset(pagination)
    return await this.$apollo.query({
      query: phoneNumbers,
      fetchPolicy: 'no-cache',
      variables: { tenantId, limit, offset, search }
    }).then(res => res?.data.phoneNumbers as PhoneNumberOriginTypeNodeConnection)
  }

  async createAssignPhoneNumbers (
    tenantId: string,
    phoneNumberId: string,
    loginId: string,
    primaryPhone: boolean,
    areaCode: string,
    forwardingNumber: string,
    inboxLabel: string
  ) {
    return await this.$apollo.mutate({
      mutation: createAssignPhoneNumbers,
      variables: {
        tenantId,
        id: phoneNumberId,
        loginId,
        primaryPhone,
        areaCode,
        forwardingNumber,
        inboxLabel
      }
    }).then(res => res?.data?.createAssignPhoneNumbers as CreateAssignPhoneNumbers)
  }

  async releasePhoneNumber (phoneNumberId: string, tenantId: string) {
    return await this.$apollo.mutate({
      mutation: releasePhoneNumber,
      variables: { phoneNumberId, tenantId }
    }).then(res => res?.data?.releasePhoneNumber as ReleasePhoneNumber)
  }
}
